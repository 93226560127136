@import '~bootstrap/dist/css/bootstrap.min.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

/* ==============================================================
Bootstrap tweaks
===============================================================*/

body, h1, h2, h3, h4 {
    font-weight: 300;
}

body {
    font-family: Arial, Helvetica, sans-serif!important;
}

a {
    color: #007ad9;
    font-weight: bold;
}

a:hover {
    color: #533f03;
    font-weight: bold;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
.ng-valid[required], .ng-valid.required  {
  border: 1px solid green;
  border-radius: 0.25em;
}

.ng-invalid:not(form)  {
  border: 3px solid red;
  border-radius: 0.25em;
}


.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 4px 10px;
}

/* other generic styles */

.jh-card-logged {
    padding: 1.5%;
    margin-top: 40px;
    border: none;
    background-color:#eee;
}

.jh-card {
    /*padding: 1.5%;*/
    border: none;
    background-color:#fff;
}

.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.readonly {
    background-color: #eee;
    opacity: 1;
}

.footer {
    border-top: 1px solid rgba(0,0,0,.125);
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand, [jhisortby] {
    cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .row.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .row.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .card-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-secondary,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-secondary {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}

.thread-dump-modal-lock {
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
 }

.acteurmodal .modal-dialog{
    max-width: 1200px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal !important;
}

.custom-button-round, .custom-button-dark-blue, #fileuploadfinalisationbtn .p-button{
    border-radius: 20px;
}

.custom-button-dark-blue{
    border-color: #007ad9!important;
    background-color: #007ad9 !important;;
}

#fileuploadfinalisationbtn > .p-fileupload {
    height:100%
}

.height100{
    height:100%
}
